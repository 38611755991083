import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { calendarConfig } from './com/mekum-admin/params/calendar-params';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './com/mekum-admin/interceptor/api.interceptor';
import { CookieModule, CookieService } from 'ngx-cookie';
import { GuardService } from './com/mekum-admin/services/guard.service';
import { AuthGuard } from './com/mekum-admin/services/auth.guard';
import { TranslationModule } from './translation.module';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { JwtInterceptor } from './com/mekum-admin/interceptor/jwt.interceptor';
@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    HttpClientModule,
    TranslationModule,
    MessagesModule
  ],
  providers: [{ provide: 'CALENDAR_CONFIG', useValue: calendarConfig },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
  CookieService,
  MessageService,
  AuthGuard, GuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
