import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient) {}

  public postData(apiUrl: string, body: {}): Observable<any[]> {
    // Implement your logic to fetch data from the server
   
    return this.http.post<any[]>(apiUrl, body);
  }

  public getData(apiUrl: string, params: HttpParams): Observable<any[]> {
    // Implement your logic to fetch data from the server
   
    return this.http.get<any[]>(apiUrl, { params });
  }

  public putData(apiUrl: string, body: {}): Observable<any> {
    // Implement your logic to fetch data from the server
   
    return this.http.put<any>(apiUrl, body);
  }

}
