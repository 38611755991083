import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, finalize, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private _updateTokenEvent$: Subject<boolean> = new Subject<boolean>();
    private _updateTokenState: Observable<boolean>;
    private _loading: boolean = false;

    constructor(
        private _router: Router,
        private _cookieService: CookieService,
        private _httpClient: HttpClient
    ) {
        this._updateTokenState = this._updateTokenEvent$.asObservable();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return next.handle(req).pipe(
            catchError((err) => {
                const error = err.error;
                if (err.status === 401 || (error && error.code && error.code === 401)) {
                    this._router.navigate(['/auth']);
                }
                return throwError(err);
            }))
    }
}
