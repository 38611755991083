import { environment } from '../../../../environments/environment';

const API_URL = environment.baseURL;
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private _cookieService: CookieService,
    private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = (req.headers) ? req.headers : new HttpHeaders();
    let params = (req.params) ? req.params : new HttpParams();
      const accessToken = this._cookieService.get('Company-Admin-Token');

      if (accessToken) {
        headers = headers.append('Authorization',  `Bearer ${accessToken}`);
      }

    const clonedRequest = req.clone({
      headers,
      params,
      url: `${API_URL}${req.url}`
    });
    return next.handle(clonedRequest);
  }

}